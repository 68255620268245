@use '../CodeWindow.module' as *;

%angle, %block {
  border-radius: $code-window-border-radius;
  margin: 0 $code-line-space;
  pointer-events: none;
}

%angle {
  border-bottom: $code-block-size * 0.5 solid transparent;
  border-top: $code-block-size * 0.5 solid transparent;
  transition: border-color 25ms;
}

%block {
  $background-color: #333333;
  background-color: $background-color;
  height: $code-block-size;
  transition: background-color 25ms;
  width: $code-block-size;
  &.active { background-color: #2e2e2e; }
}

// animation

@keyframes animated {
  from { transform: translateY(0.0625em); }
  to { transform: translateY(0); }
}

.animated {
  animation: animated .05s steps(1);
}

// blocks

.indent {
  @extend %block;
  background-color: transparent;
  &.active { background-color: transparent; }
}

.tagName {
  @extend %block;
  &.color { background-color: #569cd6; }
}

.attribute {
  @extend %block;
  &.color { background-color: #9cdcfe; }
}

.string {
  @extend %block;
  &.color { background-color: #ce9178; }
}

.value {
  @extend %block;
  &.color { background-color: #d4d4d4; }
}

.operator {
  @extend %block;
  height: $code-block-size * 0.5;
  margin: 0 ($code-block-size * 0.25) + $code-line-space;
  width: $code-block-size * 0.5;
  &.color { background-color: #d4d4d4; }
}

// angles

.closeAngle {
  @extend %angle;
  border-left: 0.8 * $code-block-size solid #333333;
  margin-right: 0.2 * $code-block-size + $code-line-space;
  &.active { border-left-color: #2e2e2e; }
  &.color { border-left-color: #d4d4d4; }
}

.openAngle {
  @extend %angle;
  border-right: 0.8 * $code-block-size solid #333333;
  margin-left: 0.2 * $code-block-size + $code-line-space;
  &.active { border-right-color: #2e2e2e; }
  &.color { border-right-color: #d4d4d4; }
}
