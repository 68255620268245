.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  > button {
    background: none;
    border: none;
    color: #d4d4d4;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
    font-size: 1.875em;
    margin: 5vh 0;

    &:active {
      transform: translateY(0.125em);
    }
  }
}

.section {
  background-color: #202020;
  border-radius: 0.15em 0.15em 0 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0 0.1875em 0.5em;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  max-width: 100%;
  width: 50em;

  a {
    text-decoration: none;
    color: #ce9178;

    &:hover {
      text-decoration: underline;
    }
  }
}

.article {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1.125em 2.25em;

  &:first-of-type {
    padding-top: 2.25em;
  }

  &:last-of-type {
    padding-bottom: 2.25em;
  }

  > img,
  > svg {
    background-color: #18191a;
    border-radius: 100%;
    border: 0.35em solid #18191a;
    box-sizing: border-box;
    height: 8em;
    margin-right: 1.125em;
    min-height: 8em;
    min-width: 8em;
    pointer-events: none;
    width: 8em;
  }

  > p {
    font-size: 1.5em;
    margin: 0 0 0 1.125em;
    width: 100%;

    img,
    svg {
      height: 1.125em;
      margin: 0 0.05em;
      pointer-events: none;
      vertical-align: top;
      width: 1.125em;
    }
  }
}

.footer {
  height: 4em;
  display: flex;
  flex-wrap: wrap;

  a:hover {
    text-decoration: none;
  }

  a,
  button {
    align-items: center;
    background-color: #151515;
    border: none;
    color: #d4d4d4;
    cursor: pointer;
    display: flex;
    flex-grow: 1;
    font-family: 'Roboto', sans-serif;
    font-size: 1.5em;
    height: 100%;
    justify-content: center;
    margin: 0;
    min-width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:active {
      transform: translateY(0.125em);
    }

    &:hover {
      background-color: darken(#151515, 2.5%);
    }

    svg {
      height: 1em;
      margin-right: 0.5em;
      width: 1em;
    }
  }
}

// shrink content to fit screen

@media only screen and (max-height: 675px), screen and (max-width: 885px) {
  .wrapper, .footer, .section {
    font-size: 1.8vmin;
  }
}

// portrait orientation

@media only screen and (max-width: 610px) {
  .article, .footer {
    font-size: 1.675em;
  }

  .wrapper > button {
    font-size: 2.75em;
  }

  .article {
    flex-direction: column;
    padding-left: 1.2em;
    padding-right: 1.2em;
    text-align: center;

    > img,
    > svg {
      margin: 0 0 1.125em;
    }

    > p {
      margin: 0 auto;
    }
  }
}
