// global config
$code-block-size: 1.25em;
$code-line-space: 0.275em;
$code-window-animation-open-speed: .75s;
$code-window-border-radius: 0.15em;
$code-window-sidebar-width: 2.25em;

:export {
  // imported in CodeWindow.jsx to delay code generation
  codeWindowAnimationOpenSpeed: $code-window-animation-open-speed;
  // imported in CodeBlock.jsx to calculate widths
  codeBlockSize: $code-block-size;
  codeLineSpace: $code-line-space;
}

// component config
$code-window-border-width: 0.25em;
$code-window-title-bar-button-hover-brightness: 20%;
$code-window-title-bar-button-size: 0.95em;
$code-window-title-bar-height: 2.25em;
$visibility-transition-speed: 100ms;

.wrapper {
  background-color: #202020;
  border-radius: $code-window-border-radius;
  box-shadow: rgba(0, 0, 0, 0.35) 0 0.3125em 0.9375em;
  display: flex;
  flex-direction: column;
  height: 28.125em;
  margin: 5vh 0;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  width: 50em;

  &::before {
    background: #333333;
    border-left: $code-window-border-width solid #3c3c3c;
    border-radius: $code-window-border-radius 0 0 $code-window-border-radius;
    content: '';
    height: 100%;
    position: absolute;
    width: $code-window-sidebar-width;
  }
}

.title {
  align-items: center;
  background-color: #3c3c3c;
  display: flex;
  flex-shrink: 0;
  height: $code-window-title-bar-height;
  padding: 0 $code-window-border-width;
  z-index: 1;

  div:nth-child(1) {
    $background-color: #0078d7;
    background-color: $background-color;
    border-radius: $code-window-border-radius;
    display: inline-block;
    height: $code-window-title-bar-button-size;
    margin: 0 ($code-window-sidebar-width * 0.5 - $code-window-title-bar-button-size * 0.5);
    width: $code-window-title-bar-button-size;

    &:hover {
      $hover-color: lighten($background-color, $code-window-title-bar-button-hover-brightness);
      background-color: $hover-color;
      box-shadow: 0 0 0.5em rgba($hover-color, 0.15);
    }
  }

  div:nth-child(2),
  div:nth-child(3),
  div:nth-child(4) {
    border-radius: 100%;
    height: $code-window-title-bar-button-size;
    margin: 0 $code-window-border-width;
    width: $code-window-title-bar-button-size;
  }

  div:nth-child(2) {
    $background-color: #498205;
    background-color: $background-color;
    margin-left: auto;

    &:hover {
      $hover-color: lighten(
        $background-color,
        calc($code-window-title-bar-button-hover-brightness / 1.5)
      );
      background-color: $hover-color;
      box-shadow: 0 0 0.5em rgba($hover-color, 0.15);
    }
  }

  div:nth-child(3) {
    $background-color: #ffb900;
    background-color: $background-color;

    &:hover {
      $hover-color: lighten($background-color, $code-window-title-bar-button-hover-brightness);
      background-color: $hover-color;
      box-shadow: 0 0 0.5em rgba($hover-color, 0.15);
    }
  }

  div:nth-child(4) {
    $background-color: #de3249;
    background-color: $background-color;

    &:hover {
      $hover-color: lighten($background-color, $code-window-title-bar-button-hover-brightness);
      background-color: $hover-color;
      box-shadow: 0 0 0.5em rgba($hover-color, 0.15);
    }
  }
}

.body {
  border-color: #3c3c3c;
  border-style: solid;
  border-width: 0 $code-window-border-width;
  height: inherit;
  position: relative;
}

.code {
  bottom: 0; // IE11
  box-shadow: rgba(0, 0, 0, 0.06) 0 0.125em 0.25em 0 inset;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  left: 0; // IE11
  overflow-y: scroll;
  position: absolute;
  right: 0; // IE11
  scrollbar-color: #18191a; // chrome
  top: 0; // IE11
  width: 100%;

  &::before {
    // empty padding to stick code to
    // the top of the animator Y axis
    content: '';
    font-size: 1em;
    height: 100%;
    margin-bottom: $code-line-space;
    min-height: 5 * ($code-block-size + $code-line-space * 2) - 0.1em; // [- val] hack to perfectly align scroll position
    pointer-events: none;
  }

  &::-webkit-scrollbar { width: 1em; }
  &::-webkit-scrollbar-track { background: #18191a; }
  &::-webkit-scrollbar-thumb { background-color: #333333; }
  &::-webkit-scrollbar-thumb:hover { background-color: #4f4f4f; }
}

.name {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: calc($code-window-border-radius / 2);
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0 0.078em 0.235em;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 2.875em;
  height: 1.65em;
  justify-content: center;
  left: 0;
  margin: auto;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity $visibility-transition-speed;
  width: 8.95em;

  &.visible {
    opacity: 1;
  }

  img {
    height: 1.125em;
    margin-right: 0.125em;
    width: 1.125em;
  }

  span {
    margin-left: 0.125em;
  }
}

.footer {
  background-color: #3c3c3c;
  border-color: #3c3c3c;
  border-style: solid;
  border-width: 0 $code-window-border-width;
  height: $code-window-border-width;
  transition:
    background-color $visibility-transition-speed,
    border-color $visibility-transition-speed,
    height $visibility-transition-speed;
  z-index: 1;

  &.visible {
    $background-color: #68217a;
    background-color: $background-color;
    border-color: $background-color;
    height: 1.5em;

    span,
    button {
      visibility: visible;
    }

    button:hover {
      background-color: darken($background-color, 10%);
    }

    .tooltip {
      background-color: $background-color;
      &::after { border-top-color: $background-color; }
    }
  }

  &.debug {
    $background-color: #cc6633;
    background-color: $background-color;
    border-color: $background-color;

    button:hover {
      background-color: darken($background-color, 10%);
    }

    .tooltip {
      background-color: $background-color;
      &::after { border-top-color: $background-color; }
    }
  }

  &.pause {
    $background-color: #cd2026;
    background-color: $background-color;
    border-color: $background-color;

    button:hover {
      background-color: darken($background-color, 10%);
    }

    .tooltip {
      background-color: $background-color;
      &::after { border-top-color: $background-color; }
    }
  }

  span,
  button {
    align-items: center;
    display: inline-flex;
    height: 100%;
    justify-content: center;
    padding: 0 0.375em;
    visibility: hidden;
    vertical-align: top;
  }

  > span {
    color: #d4d4d4;
    cursor: default;
    font-family: 'Roboto', sans-serif;
    font-size: 0.8em;

    &:nth-last-child(1) {
      float: right;
    }
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1em;
    min-width: $code-window-sidebar-width;
    position: relative;

    &:active svg {
      transform: translateY(0.09375em);
    }
  }

  svg {
    height: 1em;
    width: auto;
  }
}

.tooltip {
  border-radius: $code-window-border-radius;
  bottom: 150%;
  color: #d4d4d4;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8em;
  padding: 0.675em;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transition: background-color $visibility-transition-speed;
  visibility: hidden;
  z-index: 1;

  &::after {
    border-color: transparent;
    border-style: solid;
    border-width: 0.3125em;
    content: '';
    left: 50%;
    margin-left: -0.3125em;
    position: absolute;
    top: 100%;
  }

  button:hover & { visibility: visible; }
  button:active & { transform: translateY(0.09375em); }
}
