@use '../CodeWindow.module' as *;

// component config
$line-number-size: 0.45 * $code-block-size;

// computed values
$line-number-left-right-margin: calc($code-window-sidebar-width / 2) - calc($line-number-size / 2);
$line-number-top-bottom-margin: calc($code-block-size / 2) - calc($line-number-size / 2);

.codeLine {
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  padding: $code-line-space 0;

  &:last-of-type {
    margin-top: ($code-line-space);
  }

  &:active {
    transform: translateY(0.09375em);
  }
}

.lineNumber {
  border-radius: 100%;
  background-color: #888888;
  height: $line-number-size;
  margin-bottom: $line-number-top-bottom-margin;
  margin-left: $line-number-left-right-margin;
  margin-right: $line-number-left-right-margin * 2 - $code-line-space;
  margin-top: $line-number-top-bottom-margin;
  transition: background-color 25ms, box-shadow 25ms;
  width: $line-number-size;

  &.clicked {
    background-color: #ee4b2b;
    box-shadow: 0 0 0.5em #ee4b2b15;
    transition: background-color 50ms, box-shadow 50ms;
  }

  &.hovered {
    background-color: #d4d4d4;
    box-shadow: 0 0 0.5em #d4d4d415;
  }
}
